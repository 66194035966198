import axios  from 'axios'
/*import vueStore from '../plugins/store.js'*/
/*import Vue from 'vue';*/
/*import {router} from '../router'*/

const Env = {
  Prod: 'https://martinwilliamsapi.azurewebsites.net/api',
  Local: 'https://localhost:7038/api'
}




//Test

const instance = new axios.create({
  baseURL: Env.Prod,
  headers: {
    'X-Frame-Options': 'ALLOWALL',
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*"
  }
});

//instance.interceptors.request.use(
//  config => {
//    config.headers['Authorization'] = 'Bearer ' + store.getters.user.token;
//    return config;
//  }
//);


//instance.interceptors.response.use(response => {
//  return response;
//},
//  error => {
//    if (error.response.status === 401) {
//      vueStore.commit('RevokeToken');
//      router.push("/Login")
//    }
//    return Promise.reject(error)
//  }
//);

export default instance