<template>
  <!--<loading :active="isLoading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage"></loading>-->
  <component :is="NavbarComponent" @set-nav="SetNav" v-if="ShowNavbar && !IsSubscriberView" />
  <router-view @set-nav="SetNav" v-if="!IsSubscriberView"> </router-view>

  <div class="min-h-screen flex relative lg:static surface-ground" v-if="IsSubscriberView">
    <div id="app-sidebar-9" class="h-full lg:h-auto surface-section hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border w-18rem lg:w-7rem select-none">
      <div class="flex flex-column h-full">
        <div class="flex align-items-center justify-content-center flex-shrink-0">
          <img src="images/stackedmwilogo.jpg" alt="Image" height="70">
        </div>
        <div class="mt-0">
          <ul class="list-none p-0 m-0">
            <li>
              <a v-ripple class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-orange-700 hover:text-orange-700 transition-duration-150 transition-colors p-ripple" @click="Route('/MyDashboard')">
                <i class="pi pi-home mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                <span class="font-medium inline text-base lg:text-xs lg:block">Home</span>
              </a>
            </li>
            <li>
              <a v-ripple class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-orange-700 hover:text-orange-700 transition-duration-150 transition-colors p-ripple" @click="Route('/ELearning')">
                <i class="pi pi-book mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                <span class="font-medium inline text-base lg:text-xs lg:block">Learn</span>
              </a>
            </li>
            <li>
              <a v-ripple class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-orange-700 hover:text-orange-700 transition-duration-150 transition-colors p-ripple" @click="Route('/MyEmpowerment')">
                <i class="pi pi-bolt mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                <span class="font-medium inline text-base lg:text-xs lg:block">Empower</span>
              </a>
            </li>
            <li>
              <a v-ripple class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-orange-700 hover:text-orange-700 transition-duration-150 transition-colors p-ripple" @click="ShowConnectModal">
                <i class="pi pi-users mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                <span class="font-medium inline text-base lg:text-xs lg:block">Connect</span>
              </a>
            </li>
            <li>
              <a v-ripple class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-orange-700 hover:text-orange-700 transition-duration-150 transition-colors p-ripple" href="https://shop.martinwilliams.org/">
                <i class="pi pi-shopping-cart mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                <span class="font-medium inline text-base lg:text-xs lg:block">Shop</span>
              </a>
            </li>

            <!--<li>
    <a v-ripple class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-orange-700 hover:text-orange-700 transition-duration-150 transition-colors p-ripple">
      <i class="pi pi-calendar mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
      <span class="font-medium inline text-base lg:text-xs lg:block">Events</span>
    </a>
  </li>-->
            <li>
              <a v-ripple class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-orange-700 hover:text-orange-700 transition-duration-150 transition-colors p-ripple">
                <i class="pi pi-cog mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                <span class="font-medium inline text-base lg:text-xs lg:block">My Profile</span>

              </a>
            </li>
            <li>
              <a v-ripple class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-orange-700 hover:text-orange-700 transition-duration-150 transition-colors p-ripple" @click="LogOff">
                <i class="pi pi-lock mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                <span class="font-medium inline text-base lg:text-xs lg:block">Log Off</span>

              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>
    <div class="min-h-screen flex flex-column relative flex-auto">
      <div class="flex justify-content-end align-items-center px-5 surface-section relative lg:static border-bottom-1 surface-border" style="height:60px">
        <div class="flex">
          <a v-ripple class="cursor-pointer block lg:hidden text-700 mr-3 mt-1 p-ripple"
             v-styleclass="{ selector: '#app-sidebar-9', enterClass: 'hidden', enterActiveClass: 'fadeinleft', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutleft', hideOnOutsideClick: true }">
            <i class="pi pi-bars text-4xl"></i>
          </a>
        </div>
        <!--<a v-ripple class="cursor-pointer block md:hidden sm:hidden text-700 p-ripple"
           v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'fadein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
          <i class="pi pi-ellipsis-v text-2xl md:hidden sm:hidden xs:hidden lg:hidden"></i>
        </a>-->
      </div>
      <div class="p-5 flex flex-column flex-auto">
        <router-view @set-nav="SetNav"></router-view>
      </div>
    </div>
    <Dialog v-model:visible="ConnectModal" header="Connect With Martin Williams International" :style="{ width: '75vw' }"  :modal="true" :draggable="false">
      <div class="surface-card border-round p-3">
        <div class="mt-3">
          <div class="grid">
            <div class="col-12 md:col-6">
              <div class="text-center border-1 surface-border border-round p-4">
                <a href="https://www.twitter.com/DrMartinW" target="_blank"><i class="pi pi-twitter text-5xl text-blue-500"></i></a>
              </div>
            </div>
            <div class="col-12 md:col-6 text-center">
              <div class="text-center border-1 surface-border border-round p-4">
                <a href="https://www.instagram.com/MartinWilliamsIntl" target="_blank"><i class="pi pi-instagram text-5xl text-bluegray-500"></i></a>
              </div>
            </div>
            <div class="col-12 md:col-6 text-center">
              <div class="text-center border-1 surface-border border-round p-4">
                <a href="https://www.facebook.com/MartinWilliamsInternational" target="_blank"><i class="pi pi-facebook text-5xl text-blue-500"></i></a>
              </div>
            </div>
            <div class="col-12 md:col-6 text-center">
              <div class="text-center border-1 surface-border border-round p-4">
                <a href="https://www.youtube.com/c/MartinWilliams" target="_blank"><i class="pi pi-youtube text-5xl text-red-500"></i></a>
              </div>
            </div>
            <div class="col-12 md:col-6 text-center">
              <div class="text-center border-1 surface-border border-round p-4">
                <a href="https://www.linkedin.com/in/martin-williams-673ab8128/" class="text-blue-700" target="_blank"><i class="pi pi-linkedin text-5xl text-blue-700"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <template #footer>
        <Button label="Close" icon="pi pi-times" @click="ConnectModal = false"  class="bg-bluegray-700 border-bluegray-700" iconPos="right"/>
      </template>
    </Dialog>


  </div>
</template>

<script>
  import { ref} from "vue";
  import LandingPageNavbar from "./components/Navbars/LandingPageNavbar"
  import AdminNavbar from "./components/Navbars/AdminNavbar"
  import SignUpNavbar from "./components/Navbars/SignUpNavbar"
  import UserDashboardNavbar from "./components/Navbars/UserDashboardNavbar"
  import Button from 'primevue/button';
  // Import stylesheet
  // Import stylesheet
  /*eslint no-debugger: "error"*/
  export default {
    emits: ["set-nav"],
    name: 'App',
    components: {
      LandingPageNavbar,
      AdminNavbar,
      SignUpNavbar,
      UserDashboardNavbar,
      Button
    },
    data: () => ({
      ShowNavbar:false,
      NavbarComponent: '',
      IsAdmin: null,
      ConnectModal: false,
      IsSubscriberView: false,
      NavbarComponents: [
        "LandingPageNavbar",
        "AdminNavbar",
        "SignUpNavbar",
        "UserDashboardNavbar"
      ],
      op: ref(),
      ProfileMenu: false,
    }),
    created() {
      /* eslint-disable no-debugger, no-console */
      this.SetNav(this.$route)
    },
    methods: {
      SetNav(route) {
        this.IsSubscriberView = route.meta.IsSubscriberView

        if (!this.IsSubscriberView) {
          this.NavbarComponent = this.NavbarComponents[route.meta.NavbarIndex]
          this.ShowNavbar = route.meta.ShowNavbar
        }
      },
      ShowConnectModal() {
        this.ConnectModal = this.ConnectModal ? false : true;
      },
      Route(path) {
        this.$router.push(path)
      },
      Toggle(event) {
        this.op.toggle(event);
      },
      onTopbarNotificationMenuButtonClick(event) {
        this.notificationMenuClick = true;
        this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
      },
      hideOverlayMenu() {
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
        this.unblockBodyScroll();
      },
      blockBodyScroll() {
        if (document.body.classList) {
          document.body.classList.add('blocked-scroll');
        } else {
          document.body.className += ' blocked-scroll';
        }
      },
      unblockBodyScroll() {
        if (document.body.classList) {
          document.body.classList.remove('blocked-scroll');
        } else {
          document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
      },
      LogOff: function () {
        this.showNav = false;
        this.$store.commit('RevokeToken');
        this.$router.push('/Login');
      },
    }
}
</script>
