<template>
  <div class="min-h-100 flex relative lg:static surface-ground">
    <div id="app-sidebar-6" class="h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none w-12rem bg-bluegray-700 animation-duration-300 animation-ease-in-out">
      <div class="flex flex-column h-full">
        <div class="flex align-items-center justify-content-center flex-shrink-0" style="height:60px">
          <img src="https://www.martinwilliams.org/images/navbarlogo.png" alt="Image" height="30">
        </div>

        <div class="overflow-y-auto">
          <ul class="list-none p-3 m-0 flex flex-wrap">
            <li class="w-6 text-center">
              <a v-ripple class="cursor-pointer inline-flex align-items-center justify-content-center border-2 border-indigo-200 hover:bg-orange-800 active:bg-orange-800 text-indigo-200 hover:text-indigo-100 transition-colors transition-duration-150 p-ripple" style="width:60px;height:60px;border-radius:10px">
                <i class="pi pi-home text-2xl"></i>
              </a>
              <div class="mt-2 text-indigo-200 font-medium text-sm">Home</div>
            </li>

            <li class="w-6 text-center">
              <a v-ripple class="cursor-pointer inline-flex align-items-center justify-content-center border-2 border-indigo-200 hover:bg-orange-800 active:bg-orange-00 text-indigo-200 hover:text-indigo-100 transition-colors transition-duration-150 p-ripple" style="width:60px;height:60px;border-radius:10px" href="https://shop.martinwilliams.org/">
                <i class="pi pi-shopping-cart text-2xl"></i>
              </a>
              <div class="mt-2 text-indigo-200 font-medium text-sm">Shop</div>
            </li>

            <li class="w-6 text-center mt-3">
              <a v-ripple class="cursor-pointer inline-flex align-items-center justify-content-center border-2 border-indigo-200 hover:bg-orange-800 active:bg-orange-00 text-indigo-200 hover:text-indigo-100 transition-colors transition-duration-150 p-ripple" style="width:60px;height:60px;border-radius:10px">
                <i class="pi pi-calendar text-2xl"></i>
              </a>
              <div class="mt-2 text-indigo-200 font-medium text-sm">Events</div>
            </li>

            <li class="w-6 text-center mt-3">
              <a v-ripple class="cursor-pointer inline-flex align-items-center justify-content-center border-2 border-indigo-200 hover:bg-orange-800 active:bg-orange-700 text-indigo-200 hover:text-indigo-100 transition-colors transition-duration-150 p-ripple" style="width:60px;height:60px;border-radius:10px">
                <i class="pi pi-book text-2xl"></i>
              </a>
              <div class="mt-2 text-indigo-200 font-medium text-sm">Learn</div>
            </li>

            <li class="w-6 text-center mt-3">
              <a v-ripple class="cursor-pointer inline-flex align-items-center justify-content-center border-2 border-indigo-200 hover:bg-orange-800 active:bg-orange-00 text-indigo-200 hover:text-indigo-100 transition-colors transition-duration-150 p-ripple" style="width:60px;height:60px;border-radius:10px">
                <i class="pi pi-cog text-2xl"></i>
              </a>
              <div class="mt-2 text-indigo-200 font-medium text-sm">My Profile</div>
            </li>
          </ul>
        </div>
        <div class="mt-auto border-top-1 border-indigo-700 p-3 flex justify-content-between">
          <a v-ripple class="cursor-pointer inline-flex align-items-center justify-content-center border-2 border-indigo-500 hover:bg-indigo-700 active:bg-indigo-400 text-indigo-200 hover:text-indigo-100 transition-colors transition-duration-150 p-ripple" style="width:40px;height:40px;border-radius:10px">
            <img src="images/blocks/avatars/circle/avatar-f-6.png" style="width: 24px; height: 24px" />
          </a>
          <a v-ripple class="cursor-pointer inline-flex align-items-center justify-content-center border-2 border-indigo-500 hover:bg-indigo-700 active:bg-indigo-400 text-indigo-200 hover:text-indigo-100 transition-colors transition-duration-150 p-ripple" style="width:40px;height:40px;border-radius:10px">
            <i class="pi pi-slack text-xl"></i>
          </a>
          <a v-ripple class="cursor-pointer inline-flex align-items-center justify-content-center border-2 border-indigo-500 hover:bg-indigo-700 active:bg-indigo-400 text-indigo-200 hover:text-indigo-100 transition-colors transition-duration-150 p-ripple" style="width:40px;height:40px;border-radius:10px">
            <i class="pi pi-github text-xl"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="min-h-screen flex flex-column relative flex-auto">
      <div class="flex justify-content-between align-items-center px-5 surface-section shadow-2 relative lg:static border-bottom-1 surface-border" style="height:60px">
        <div class="flex">
          <a v-ripple class="cursor-pointer block lg:hidden text-700 mr-3 mt-1 p-ripple"
             v-styleclass="{ selector: '#app-sidebar-6', enterClass: 'hidden', enterActiveClass: 'fadeinleft', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutleft', hideOnOutsideClick: true }">
            <i class="pi pi-bars text-4xl"></i>
          </a>
        </div>
        <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple"
           v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'fadein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
          <i class="pi pi-ellipsis-v text-2xl"></i>
        </a>
        <ul class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row
                            surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
          <li>
            <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                                    transition-duration-150 transition-colors p-ripple">
              <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
              <span class="block lg:hidden font-medium">Inbox</span>
            </a>
          </li>
          <li>
            <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                                    transition-duration-150 transition-colors p-ripple">
              <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" v-badge.danger></i>
              <span class="block lg:hidden font-medium">Notifications</span>
            </a>
          </li>
          <li class="border-top-1 surface-border lg:border-top-none">
            <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer
                                    transition-duration-150 transition-colors p-ripple">
              <img src="images/blocks/avatars/circle/avatar-f-6.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px" />
              <div class="block lg:hidden">
                <div class="text-900 font-medium">Josephine Lillard</div>
                <span class="text-600 font-medium text-sm">Marketing Specialist</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="p-5 flex flex-column flex-auto">
        <div class="border-2 border-solid surface-border border-round surface-section flex-auto">
          <router-view @set-nav="SetNav"></router-view>
        </div>
      </div>
    </div>
  </div>
  </template>
  <script>

    export default {
      name: 'UserDashboardNavbar',
    
      methods: {
        Home() {
          this.$router.push('/')
        }
      }
    }
  </script>
