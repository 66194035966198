import { createRouter, createWebHistory } from 'vue-router'
import store from '../plugins/store.js'
//import axios from 'axios'


//const Env = {
//  Prod: 'https://martinwilliamsapi.azurewebsites.net/api',
//  //Staging: 'https://deiinclusologyqa.azurewebsites.net/api/',
//  //Dev: 'https://devapi.dei-inclusology.io/api/',
//  Local: 'https://localhost:7038/api/'
//}

//const axiosinstance = axios.create({
//  baseURL: Env.Local,
//  headers: {
//    'X-Frame-Options': 'ALLOWALL',
//    'Content-Type': 'application/json'
//  }
//});

const routes = [  
  {
    path: '/SignUp:Email?',
    name: 'SignUp',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SignUp.vue'),
    meta: {
      allowAnonymous: true,
      ShowNavbar: true,
      NavbarIndex: 2
    }
  },
  {
    path: '/Register',
    name: 'RegisterStepOne',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue'),
    meta: {
      allowAnonymous: true,
      ShowNavbar: true,
      NavbarIndex: 2
    }
  },

  {
    path: '/StepOneRegister',
    name: 'NewUserRegistration',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Registration/StepOneRegister.vue'),
    meta: {
      allowAnonymous: true,
      ShowNavbar: true,
      NavbarIndex: 2
    }
  },
  {
    path: '/Login:Email?',
    name: 'SignIn',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginHome.vue'),
    meta: {
      allowAnonymous: true,
      ShowNavbar: false
    }
  },
  {
    path: '/',
    name: 'Home',
    props: false,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Home.vue'),
    meta: {
      allowAnonymous: true,
      ShowNavbar: true,
      NavbarIndex: 0
    }
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    props: false,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPassword.vue'),
    meta: {
      allowAnonymous: true,
      ShowNavbar: true,
      NavbarIndex: 2
    }
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    props: false,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ResetPassword.vue'),
    meta: {
      allowAnonymous: true,
      ShowNavbar: true,
      NavbarIndex: 2
    }
  },
  {
    path: '/MyEmpowerment',
    name: 'Empowerment',
    props: false,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Empower/EmpowerModules.vue'),
    meta: {
      allowAnonymous: false,
      ShowNavbar: false,
      IsSubscriberView: true,
    }
  },
  {
    path: '/PowerTools:Email?',
    name: 'PowerTools',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductDetailViews/PowerTools.vue'),
    props: route => ({ ...route.query, ...route.params }),
    meta: {
      allowAnonymous: true,
      ShowNavbar: true,
      NavbarIndex: 2
    }
  },
  {
    path: '/SignUpStepOne:ProductId?',
    name: 'WizardStepOne',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: () => import(/* webpackChunkName: "about" */ '../views/SignUp/StepOne.vue'),
    
    meta: {
      allowAnonymous: true,
      ShowNavbar: true,
      NavbarIndex: 2
    }
  },
 
  {
    path: '/SignUpStepTwo',
    name: 'StepTwoSignUp',
    props: false,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      Signup : import(/* webpackChunkName: "about" */ '../views/SignUp/StepTwo.vue')
    },
    meta: {
      allowAnonymous: true,
      ShowNavbar: true,
      NavbarIndex: 2
    }
  },
  {
    path: '/CreateAssessment:AssessmentId?',
    name: 'CreateAssessment',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateAssessment/CreateAssessment.vue'),
    meta: {
      allowAnonymous: false,
      ShowNavbar: true,
      NavbarIndex: 1
    }
  },
  {
    path: '/BeginAssessment:AssessmentType?',
    name: 'TakeAssessment',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Assessments/AssessmentBegin.vue'),
    meta: {
      allowAnonymous: true,
      ShowNavbar: true,
      NavbarIndex: 2
    }
  },
  {
    path: '/AdminHomePage',
    name: 'AdminHome',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminDashboard.vue'),
    meta: {
      allowAnonymous: false,
      ShowNavbar: true,
      NavbarIndex: 1
    }
  },
  {
    path: '/LocateAssessment',
    name: 'AssessmentLocator',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Assessments/LocateAssessment.vue'),
    meta: {
      allowAnonymous: true,
      ShowNavbar: true,
      NavbarIndex: 2
    }
  },
  {
    path: '/MyDashboard',
    name: 'UserHome',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/UserDashboard.vue'),
    meta: {
      allowAnonymous: false,
      ShowNavbar: false,
      NavbarIndex: 3,
      IsSubscriberView: true
    }
  },
  {
    path: '/ChapterContent:ModuleId?',
    name: 'ModuleContent',
    props: true,
    component: () => import(/* webpackChunkName: "about" */  '../views/ELearning/ChapterContents.vue'),
    meta: {
      allowAnonymous: false,
      ShowNavbar: false,
      NavbarIndex: 3,
      IsSubscriberView: true
    }
  },
  {
    path: '/ManageQuestions',
    name: 'QuestionManagement',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/ManageQuestions.vue'),
    meta: {
      allowAnonymous: false,
      ShowNavbar: true,
      NavbarIndex: 1
    }
  },

  {
    path: '/ELearning',
    name: 'LearningModules',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/ELearning/LearningModules.vue'),
    meta: {
      allowAnonymous: false,
      ShowNavbar: true,
      IsSubscriberView:true
    }
  },
  {
    path: '/Courses:CourseId?',
    name: 'MyCourseModules',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/ELearning/ModuleContents.vue'),
    meta: {
      allowAnonymous: false,
      ShowNavbar: true,
      IsSubscriberView: true
    }
  },
  {
    path: '/ManageAssessments',
    name: 'ManageAssessments',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ManageAssessments.vue'),
    meta: {
      allowAnonymous: false,
      ShowNavbar: true,
      NavbarIndex: 1
    }
  },

  {
    path: '/SignUpStepThree',
    name: 'StepThreeSignUp',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      Signup: import(/* webpackChunkName: "about" */ '../views/SignUp/StepThree.vue')
    },
    meta: {
      allowAnonymous: true,
      ShowNavbar: true,
      NavbarIndex: 2
    }
  },

]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(process.env.BASE_URL),
  routes, // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
  var isauthenticated = store.getters.user.isAuthenticated;
  if ((!to.meta.allowAnonymous) && !isauthenticated) {
    next({
      path: '/Login',
      query: { redirect: to.fullPath }
    })
  }
  //else if (to.path == "/SignUp") {
  //  store.commit('RevokeToken');
  //  axiosinstance.post('/Authorization/ValidateToken', {
  //    UserName: to.query.User,
  //    Token: to.query.token
  //  }).then(function (response) {
  //    if (response.data.success) {
  //      if (response.data.isValid) {
  //        next();
  //      }
  //      else {
  //        next({
  //          name: "Error",
  //          params: {
  //            Invalid: true,
  //            message: "looks like this link has expired"
  //          },
  //          query: {}
  //        })
  //      }
  //    }
  //  })
  //}
  else {
    next();
  }
})

export default router
