<template>
      <div class="surface-overlay py-3 px-6 shadow-2 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
        <img src="https://www.martinwilliams.org/images/navbarlogo.png" alt="Image" height="40" class="mr-0 lg:mr-6" @click="Home" style="cursor:pointer">
        <a Ripple class="cursor-pointer block lg:hidden text-700 mt-1 p-ripple"
           StyleClass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
            <i class="pi pi-bars text-4xl"></i>
        </a>
    </div>
</template>

<script>
  export default {
    name: 'SignUpNavbar',
    data: () => ({
    Home() {
        this.$router.push({ path: '/' })
      },
    }),
}</script>