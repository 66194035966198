import 'es6-promise/auto'
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: {
      isAuthenticated: false,
      token: '',
      userName: '',
      organizationId: '',
      role: '',
      homepage: null,
      subscriptionId: ''
    },
    AssessmentAuth:{
       CurrentToken: null,
       UserName:null
      }
    },
   mutations: {
        RevokeToken(state) {
          state.user.token = null;
          state.user.userName = null;
          state.user.organizationId = null;
          state.user.subscriptionId = null;
          state.user.role = null;
          state.user.isAuthenticated = false;
          state.user.id = null;
        },
        SetAssessmentAuth(state, payload) {
          state.AssessmentAuth.CurrentToken = payload.token;
          state.AssessmentAuth.UserName = payload.email
        },
        SetAuth(state, payload) {
          state.user.token = payload.token;
          state.user.isAuthenticated = true;
          state.user.userName = payload.userName;
          state.user.organizationId = payload.organizationId;
          state.user.role = payload.role;
          state.user.subscriptionId = payload.subscriptionId;
          state.user.id = payload.id;

          var dashboardPage = "/AdminHomePage";

          if (payload.role == "SUBS") {
            dashboardPage = "/MyDashboard"
          }
          state.user.homepage = dashboardPage;
        }
      },

      getters: {
        user(state) { return state.user },
        assessmentAuth(state) { return state.AssessmentAuth }     
      }
  });

export default store;
 