<template> 
<div class="surface-overlay relative">
  <div class="py-3 lg:py-2 px-6 flex align-items-center justify-content-between lg:static">
    <img src="/images/navbarlogo.png" alt="Image" height="40" class="mr-0 lg:mr-6" @click="Home" style="cursor:pointer">
    <div class="flex align-items-center">
      <a Ripple class="cursor-pointer block lg:hidden text-700 mr-5 p-ripple"
         StyleClass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
        <i class="pi pi-ellipsis-v text-2xl"></i>
      </a>
      <a Ripple class="cursor-pointer block lg:hidden text-700 p-ripple"
         StyleClass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
        <i class="pi pi-bars text-4xl"></i>
      </a>
    </div>
    <div id="navbar-sub-7" class="hidden lg:block absolute lg:static lg:w-auto w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
      <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
        <li>
          <a Ripple class="flex px-6 p-3 lg:px-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
            <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
            <span class="block lg:hidden font-medium">Inbox</span>
          </a>
        </li>
        <li>
          <a Ripple class="flex px-6 p-3 lg:px-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
            <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" v-badge.danger></i>
            <span class="block lg:hidden font-medium">Notifications</span>
          </a>
        </li>
        <li class="border-top-1 surface-border lg:border-top-none">
          <a Ripple class="flex px-6 p-3 lg:px-3 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
            <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 28px; height: 28px" />
            <div class="block lg:hidden">
              <div class="text-900 font-medium">Josephine Lillard</div>
              <span class="text-600 font-medium text-sm">Marketing Specialist</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div id="navbar-7" class="py-2 px-6 shadow-2 hidden lg:block absolute lg:static lg:w-auto w-full surface-overlay left-0 top-100 z-1 shadow-2">
    <ul class="list-none p-0 m-0 flex lg:align-items-center flex-column lg:flex-row select-none">
      <li>

        <router-link to="/AdminHomePage" Ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
          <i class="pi pi-home mr-2 mr-2"></i>
          <span>Home</span>
        </router-link>
      </li>
      <li>
        <router-link to="/ManageQuestions" Ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
          <i class="pi pi-cog mr-2"></i>
          <span>Question Management</span>
        </router-link>
      </li>
      <li>
        <router-link to="/ManageAssessments" Ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
          <i class="pi pi-chart-line mr-2"></i>
          <span>Assessment Management</span>
        </router-link>
      </li>
    </ul>
  </div>
  </div>
  </template>


<script>
  export default {
   name: 'AdminNavbar',
    methods: {
      Home() {
        this.$router.push('/')
      },
      QuestionManagment() {
        this.$router.push('/ManageQuestions')
      }
    }
  }
</script>
