
<template>
  <nav class="navbar  navbar-expand-sm fixed-top  navbar-trans">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <a class="navbar-brand" href="#">
        <img src="/images/navbarlogo.png" alt="MWI Logo" height="55" class="navbar-logo">
      </a>

      <button class="navbar-toggler text-dark flex-grow-1 text-right" type="button" data-toggle="collapse" data-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars"></i>
      </button>

      <div class="collapse navbar-collapse flex-grow-1 text-right" id="navbarNav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item"><a class="nav-link scroll" href="#home">Home<span class="sr-only"></span></a></li>
          <li class="nav-item"><a class="nav-link scroll" href="#about">About Us<span class="sr-only"></span></a></li>
          <li class="nav-item"><a class="nav-link scroll" href="#events">Events<span class="sr-only"></span></a></li>

          <li class="nav-item"><a class="nav-link scroll" href="#powertools">Power Tools Assessment &trade; <span class="sr-only"></span></a></li>
          <li class="nav-item"><a class="nav-link" href="https://shop.martinwilliams.org">Shop<span class="sr-only"></span></a></li>
          <li class="nav-item"><a class="nav-link scroll" href="#partner">Partner With Us<span class="sr-only"></span></a></li>
          <!--<li class="nav-item"><a class="nav-link"  href="https://billing.stripe.com/p/login/fZecPie0y9Vm5Wg144">Partner Billing Portal<span class="sr-only"></span></a></li>-->
          <li class="nav-item"><a class="nav-link scroll" href="#contactUs">Connect<span class="sr-only"></span></a></li>
          <!--<li class="nav-item"><a class="nav-link scroll" href="#give">Give<span class="sr-only"></span></a></li>-->

          <li class="nav-item mr-lg-4">
            <router-link to="/Login" class="nav-link"> Login </router-link>

            <!--<a class="nav-link scroll" href="#" @click="Login">Login<span class="sr-only"></span></a>-->
          </li>


        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    name: 'LandingPageNavbar',

  data: () => ({

  }),
}</script>